import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBK39E41GYc11QnYENl7ucYnF_gJmIUMNU",
  authDomain: "nidw-30fc2.firebaseapp.com",
  databaseURL: "https://nidw-30fc2-default-rtdb.firebaseio.com",
  projectId: "nidw-30fc2",
  storageBucket: "nidw-30fc2.appspot.com",
  messagingSenderId: "504721414814",
  appId: "1:504721414814:web:bda189240e37577cc7b98f",
  measurementId: "G-N3VWK6L8CG",

  // apiKey: "AIzaSyBK39E41GYc11QnYENl7ucYnF_gJmIUMNU",
  // authDomain: "nidw-30fc2.firebaseapp.com",
  // databaseURL:
  //   "https://nidw-30fc2-default-rtdb.firebaseio.com",
  // projectId: "nidw-30fc2",
  // storageBucket: "nidw-30fc2.appspot.com",
  // messagingSenderId: "504721414814",
  // appId: "1:504721414814:web:bda189240e37577cc7b98f",
  // measurementId: "G-N3VWK6L8CG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getDatabase();
export { app, analytics, auth, db };
